import { EnvVariables } from './environment.model';

export const environment: EnvVariables = {
  apiUrl: {
    ornis: `https://ornis.sandbox.dotbank.africa/api/v1`,
    canary: `https://uat.canary.dotbank.africa/api/v1`,
  },
  keycloak: {
    url: 'https://keycloak.sandbox.dotbank.africa',
    client_id: 'backoffice',
    realmName: 'dot',
  },
};
